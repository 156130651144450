<template>
  <v-card class="ma-0 pa-2">
    <v-card-title>
      Id: {{ selectedSiteGroup.id }} - {{ selectedSiteGroup.name }}
    </v-card-title>
    <v-row class="">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="selectedSitesFiltered"
          :sort-by="'name'"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-text-field
              v-model="searchStr"
              @input="search"
              append-icon="mdi-magnify"
              label="Recherche... "
              single-line
              hide-details
              class="ma-2"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-card-text>
    </v-row>
  </v-card>
</template>
<script>
import { ref } from "@vue/composition-api";
import axios from "@axios";
import { mdiMagnify } from "@mdi/js";

export default {
  name: "SiteGroupModalView",
  props: {
    siteGroupId: {
      type: Number,
      default: () => {
        return null;
      },
      validator(value) {
        let intValue = parseInt(value);
        return intValue == value && value >= 1;
      },
    },
    sites: {
      type: Array,
      default: () => {
        return [];
      },
      validator(value) {
        return value.length >= 1;
      },
    },
  },
  async created() {
    if (this.siteGroupId) {
      const { data } = await axios.get(`/site-groups/${this.siteGroupId}`);
      this.setSiteGroup(data);
    }
  },
  setup() {
    const isSnackbarVisible = ref(false);
    return {
      isSnackbarVisible,
    };
  },
  methods: {
    setSiteGroup(data) {
      this.selectedSiteGroup.id = data.pk;
      this.selectedSiteGroup.name = data.name;
      const selectedSitesId = data.sites;
      this.selectedSites = this.sites.filter((site) =>
        selectedSitesId.includes(site.id)
      );
      this.selectedSitesFiltered = this.selectedSites;
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        if (this.searchStr == "") {
          this.selectedSitesFiltered = this.selectedSites;
          return;
        }
        const selectedSitesFiltered = [];
        const searchStrLC = this.searchStr.toLowerCase();
        for (let i = 0; i < this.selectedSites.length; i++) {
          if (
            this.selectedSites[i]["id"] == searchStrLC ||
            this.selectedSites[i]["name"].toLowerCase().includes(searchStrLC) ||
            this.selectedSites[i]["domain"]
              .toLowerCase()
              .includes(searchStrLC) ||
            this.selectedSites[i]["code"].toLowerCase().includes(searchStrLC)
          ) {
            selectedSitesFiltered.push(this.selectedSites[i]);
          }
        }
        this.selectedSitesFiltered = selectedSitesFiltered;
      }, 500);
    },
  },
  data() {
    return {
      selectedSiteGroup: {
        id: null,
        name: "",
      },
      searchStr: "",
      searchTimeout: null,
      selectedSites: [],
      selectedSitesFiltered: [],
      headers: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Domain",
          value: "domain",
        },
      ],
      icons: {
        mdiMagnify,
      },
    };
  },
  watch: {
    siteGroupId: async function (newVal, oldVal) {
      // watch it
      if (this.siteGroupId) {
        const { data } = await axios.get(`/site-groups/${this.siteGroupId}`);
        this.setSiteGroup(data);
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>
<style scoped></style>
